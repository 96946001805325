<template>
  <div>
    <v-card class="mt-9">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h5 class="font-weight-light">BILLING REPORT</h5>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md4 class="mx-2">
          <v-date-picker
            :landscape="false"
            v-model="month_of"
            type="month"
            min="2023-08"
            @change="view_data"
            dense
          ></v-date-picker>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md3 class="mx-2">
          <v-select
            v-model="branch_id"
            dense
            outlined
            label="Branch"
            item-text="branch_code"
            item-value="id"
            :items="branch_items"
            @change="view_data"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="align-center mx-2 mb-5"  v-if="!printingss && data_items.length > 0">
        <v-flex xs12 md3 class="mx-2">
          Print
          <v-icon
            v-if="!printingss && data_items.length > 0"
            color="success"
            @click="print_data()"
          >
            {{ icons.mdiPrinter }}
          </v-icon>
          <v-progress-circular
            color="info"
            indeterminate
            v-if="printingss"
          ></v-progress-circular>
        </v-flex>
      </v-layout>
      <v-data-table dense
                    :headers="headers"
                    :items="data_items"
                    :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Data</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearch"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.id }}
            </td>
            <td>
              {{ item.branch_information.branch_code }}
            </td>
            <td>
              {{ item.branch_data.room_no }}
            </td>
            <td>
              {{ item.members_information != null ? item.members_information.company_name : '' }}
            </td>
            <td>
              {{ item.due_date }}
            </td>
            <td>
              {{ item.or_date }}
            </td>
            <td>
              {{ item.or_no }}
            </td>
            <td>
              {{ formatPrice(item.amount) }}
            </td>
            <td>
              {{ item.remarks }}
            </td>
            <td>
              <v-chip color="error" v-if="item.deposit_slip_id===null">
                UNPAID
              </v-chip>
              <v-chip color="success" v-else-if="item.deposit_slip_id!=null&&item.is_consumed===0">
                PAID
              </v-chip>
              <v-chip color="warning" v-else-if="item.deposit_slip_id!=null&&item.is_consumed===1">
                CONSUMED
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="is_view_rooms" max-width="90%">
      <BranchRooms :selected_branch="this.selected_branch"/>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
import {
  mdiCheck, mdiClose
  , mdiAccountOff
  , mdiAccountCheck
  , mdiPlus
  , mdiEyeCircle, mdiPrinter
} from "@mdi/js";
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
import BranchRooms from '@/views/register_interface/branch_component/BranchRooms'

const initialState = () => {
  return {
    month_of: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    is_view_rooms: false,
    selected_branch: {},
    data_items: [],
    branch_id: '',
    branch_items: [],
    search: '',
    printingss: false,
    headers: [
      {text: 'ID', value: 'id', sortable: true},
      {text: 'Branch', value: 'branch_information.branch_code', sortable: true},
      {text: 'Room #', value: 'branch_data.room_no', sortable: true},
      {text: 'Name', value: 'members_information.company_name', sortable: true},
      {text: 'Due Date', value: 'due_date', sortable: true},
      {text: 'OR Date', value: 'or_date', sortable: true},
      {text: 'OR #', value: 'or_no', sortable: true},
      {text: 'Amount', value: 'amount', sortable: true},
      {text: 'Remarks', value: 'remarks', sortable: true},
      {text: 'Status', value: 'status', sortable: true},
    ],
  }
}
export default {
  components: {
    snackBarDialog,
    BranchRooms,
  },
  setup() {
    return {
      icons: {
        mdiCheck,
        mdiClose,
        mdiAccountOff
        , mdiAccountCheck
        , mdiPrinter
        , mdiPlus
        , mdiEyeCircle
      },
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    this.initialize_data()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['name', 'position']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('branch_data_payments', ['list_of_unpaid_rental']),
    ...mapActions('branch_information', ['list_of_branch']),
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    initialize_data() {
      const data = new FormData()
      data.append('is_active', 1);
      this.list_of_branch(data)
        .then(response => {
          this.branch_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    view_data(item) {
      const data = new FormData()
      data.append('month_of', this.month_of);
      data.append('branch_id', this.branch_id);
      this.list_of_unpaid_rental(data)
        .then(response => {
          this.data_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    print_data() {
      var imgData = this.company_logo
      var payments_array = []
      var widths = []
      var payment = 0
      var info_array = []

      widths = [80, 60, 80, 40,40, 40, 40, 40,40]
      payments_array.push([
        {text: 'Branch', alignment: 'left', style: 'main_info',},
        {text: 'Room #', alignment: 'left', style: 'main_info'},
        {text: 'Name', alignment: 'left', style: 'main_info'},
        {text: 'Due Date', alignment: 'left', style: 'main_info'},
        {text: 'Or Date', alignment: 'left', style: 'main_info'},
        {text: 'Or #', alignment: 'left', style: 'main_info'},
        {text: 'Amount', alignment: 'left', style: 'main_info'},
        {text: 'Remarks', alignment: 'left', style: 'main_info'},
        {text: 'Status', alignment: 'left', style: 'main_info'},
      ])
      this.data_items.forEach(function (item) {
        payment += parseFloat(item.amount)
        payments_array.push([
          {
            text: item.branch_information.branch_code ,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.branch_data.room_no ,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.members_information != null ? item.members_information.company_name : '' ,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.due_date,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.or_date,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.or_no,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: (item.amount / 1)
              .toFixed(2)
              .replace(',', '.')
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.remarks,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.deposit_slip_id===null?'UNPAID':(item.deposit_slip_id!=null&&item.is_consumed===0?'PAID':'CONSUMED'),
            alignment: 'left',
            style: 'tableExample2'
          },
        ])
      })
      info_array = [
        {
          columns: [
            {
              style: 'main_info',
              text: [
                'Month of: ',
                {
                  text: this.month_of,
                  style: 'main_data',
                },
              ],
            },
          ],
        },
        {
          columns: [
            {
              style: 'main_info',
              text: [
                'Branch: ',
                {
                  text: this.branch_items[
                    this.branch_items
                      .map(function (x) {
                        return x.id
                      })
                      .indexOf(this.branch_id)
                    ].branch_code,
                  style: 'main_data', color: 'red',
                },
              ],
            },
          ],
        },
      ]

      //call pdfmake
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs
      }
      var docDefinition = {
        pageSize: {
          width: 612,
          height: 936,
        },
        pageOrientation: 'portrait',
        content: [
          {
            columns: [
              {image: imgData, width: 54, height: 54, style: 'logo'},
              {
                stack: [
                  'GOODLIFE BUILDINGS',
                  {text: 'SAN JOSE, DIGOS CITY DAVAO DEL SUR 8002', style: 'subheader'},
                  {
                    text: 'Contact #: 09',
                    style: 'subheader',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
            ],
          },
          '_______________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    text: 'BILLING REPORT',
                    style: {
                      fontSize: 16,
                      alignment: 'center',
                      bold: true,
                      color: 'blue',
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: [{text: ' '}],
          },
          {
            columns: [{text: ' '}],
          },
          {
            stack: info_array,
          },
          {
            columns: [{text: ' '}],
          },
          {
            table: {
              widths: widths,
              body: payments_array,
              style: {fontSize: 1},
            },
          },
          {
            stack: [
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [
                  {
                    text: 'PROCESSED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: 'DEPARTMENT RECEIVER',
                    style: 'main_data_2',
                  },
                  {
                    text: 'RECEIVED BY',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.name,
                    style: 'main_data_2',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.position,
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'VERIFIED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_________________________________________',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'BOARD OF DIRECTOR',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
            ],
          },
        ],
        footer: {
          // columns: [
          //     {
          //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
          //         alignment: 'center',
          //         style: 'tableExample'
          //     }
          // ]
        },
        styles: {
          tableExample: {
            fontSize: 9,
          },
          tableExample2: {
            fontSize: 7,
          },
          header: {
            fontSize: 21,
            bold: true,
            alignment: 'left',
            margin: [6, 6, 0, 5], //[left, top, right, bottom]
          },
          subheader: {
            fontSize: 11,
          },
          logo: {
            alignment: 'center',
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          printed_label: {
            alignment: 'right',
            fontSize: 9,
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          main_data: {
            margin: [0, 2, 0, 2],
            fontSize: 11,
            bold: true,
            alignment: 'left',
          },
          main_data_2: {
            margin: [0, 2, 0, 2],
            fontSize: 8,
            bold: true,
            alignment: 'left',
          },
          main_info: {
            margin: [0, 2, 0, 2],
            fontSize: 10,
            alignment: 'left',
          },
        },
      }
      pdfMake.createPdf(docDefinition).open()
    }
  },
}
</script>
